import { FlightProps } from "../components/Flight/Flight.types";
import { GuestCheckInStatus, GuestDetails, GuestMessages, GuestNotes, GuestSignedWaivers } from "../components/Guest/Guest";
import { useSrefActive } from "@uirouter/react";
import { FlightsListView } from "./FlightsList";

const BuildSquareLink = (): void => {
  const dataParameter = {
    amount_money: {
      amount: '100',
      currency_code: "USD"
    },
    // Replace this value with your application's callback URL
    callback_url: "https://rezflow.ngrok.io/check-in/square",
    // Replace this value with your application's ID
    client_id: "sq0idp-0hw2XtpnML-TrhoSTKwHng",
    state: "NBV1234",
    version: "1.3",
    notes: "notes for the transaction",
    options: {
      supported_tender_types: ["CREDIT_CARD"]
    }
  };
  window.location.href = `square-commerce-v1://payment/create?data=${encodeURIComponent(JSON.stringify(dataParameter))}`;
}


const GuestDetailsView = ({ guest }: { guest: FlightProps }): JSX.Element => {
  const indexSref = useSrefActive(FlightsListView.name, { date: guest.day }, 'active');
  return (
    <div className="w-container" style={{ 'paddingTop': '40px', 'paddingBottom': '40px' }}>
      <a {...indexSref} className="close-page-button w-button">CLOSE</a>
      <div className="w-layout-grid guest-info-grid">
        <GuestDetails guest={guest}/>
      </div>

      <div className="w-layout-grid check-in-status-grid">
        <GuestCheckInStatus guest={guest} />
      </div>
      
      <div>
        <GuestSignedWaivers guest={guest} />
      </div>

      <div>
        <GuestNotes guest={guest} />
      </div>

      <div>
        <GuestMessages guest={guest} />
      </div>

      <div>
        <strong>In development, do not touch</strong>
        <button type="button" className="resend-waiver-button w-inline-block" onClick={ () => BuildSquareLink() }>
          <div>Waiver QR</div>
        </button>
      </div>


    </div>
    
  )
}

export default GuestDetailsView;