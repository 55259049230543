import router, { StateService, Transition } from "@uirouter/react";
import { useEffect, useLayoutEffect, useState } from "react";
import { tap } from "rxjs";
import UserService from "../components/User/user.service";
import { authStore, companyModalStore, companyStore, userStore } from "../store/store.state";
import { FlightsListView } from "./FlightsList";
import Cookies from 'universal-cookie';

export const UserLoginForm = ({ transition }: { transition: Transition }) => {
  const [, setUser] = useState(userStore.initialState);
  const [, setToken] = useState(authStore.initialState);
  const [form, submitForm] = useState({ email: '', password: ''});
  
  useLayoutEffect(() => {
    userStore.subscribe(setUser);
    userStore.init();
    authStore.subscribe(setToken);
    authStore.init();
  });
  
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const login = (await UserService.login(form.email, form.password)).data;

    const cookies = new Cookies();
    const redir = cookies.get('redir-path');
    // TODO: this is insecure - create refresh flow and expire token.
    cookies.set('checkin-token', login.access_token, { path: '/' });
    cookies.set('checkin-user', JSON.stringify(login.user), { path: '/' });
    userStore.updateState(login.user);
    authStore.updateState(login.access_token);
    companyStore.updateState(login.user.company[0]);
    if (redir) {
      cookies.remove('redir-path');
      // eslint-disable-next-line no-restricted-globals
      location.href = redir;
    } else {
      transition.router.stateService.go(FlightsListView.name, { date: new Date().toISOString().split('T')[0] });
    }
  }

  function updateFormState(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    submitForm({ ...form, [name]: value });
  }

  return (
    <>
      <div className="w-container">
        <form className="column-names" onSubmit={ (e) => handleSubmit(e) }>
          <div className="w-row">
            <div className="w-col w-col-5">
              {/* <label htmlFor="email" className="field-label">Email Address</label> */}
              <input type="email" name="email" className="text-field w-input" id="email" placeholder="Email Address" onChange={updateFormState}/>
            </div>
            <div className="w-col w-col-5">
              {/* <label htmlFor="password" className="field-label">Password</label> */}
              <input type="password" name="password" className="text-field w-input" id="password" placeholder="Password" onChange={updateFormState}/>
            </div>
            <div className="w-col w-col-2">
              <button type="submit" className="w-button">Login</button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
};
