import { UIView } from "@uirouter/react";

const App = () : JSX.Element => {

  return (
    <UIView />
  );
}

export default App;
