import axios, { AxiosError } from "axios";
import { authStore, companyStore } from "../../store/store.state";
import UserService from "../User/user.service";
import { TipDto } from "./tip.interface";

export default class TipService {
  static async getEvent(date: string) {
    try {
      return await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/event/${date}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async getSquareTips(date: string, pilot: string) {
    try {
      const squareTips = await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/square-tips/${date}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
      return squareTips;
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async getHackedSquareTips(date: string, pilot: string) {
    try {
      const squareTips = await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/rezflow-tips/square/${date}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
      return squareTips;
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }


  static async getRezflowTips(date: string, pilot: string) {
    try {
      const squareTips = await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/rezflow-tips/${date}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
      return squareTips;
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async getTip(date: string, pilot: string) {
    try {
      return await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/tip/${date}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async saveTip(tip: TipDto) {
    try {
      return await axios.post(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/tip`,
        tip,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async updateTip(id: string, tip: TipDto) {
    try {
      return await axios.put(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/tip`,
        { _id: id, ...tip },
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }
}
