import styles from './Header.module.css';
import React, { useEffect, useState } from 'react';
import { companyModalStore, companyStore, routerStore, userStore } from '../../store/store.state';
import UserService from '../User/user.service';
import { useSrefActive, useIsActive } from '@uirouter/react';
import TipsView from '../../views/TipsView';
import { FlightsListView } from '../../views/FlightsList';

export const CompanyModal = (): JSX.Element => {
  const [display, setModalState] = useState(companyModalStore.data);
  const [company, setActiveCompany] = useState({});

  companyModalStore.subject.subscribe(({data}) => {
    setModalState(data)
  });

  const closeModal = () => setModalState(false);

  const selectCompany = (c: any) => {
    const router = routerStore.data;
    setActiveCompany(c);
    UserService.setActiveCompany(c);
    setModalState(false);
    router?.stateService.reload()
  }

  const availableCompanies = userStore.data?.company;

  return (
    <div className="pop-up-modal" style={{ 'display': display ? 'block' : 'none'}}>
      <button onClick={() => closeModal() } type="button" className="closing-x" style={{ 'background': 'none' }}>X</button>
      <div className="w-layout-grid grid">
        {
          availableCompanies?.map((c, index) => {
            return (
              <div key={index} className="companyGrid select-company-button" onClick={ (e) => selectCompany(c) }>{c.nickname}</div>
            );
          })
        }
      </div>
    </div>
  );
}

export const HeaderBar = (): JSX.Element => {

  const [company, setCompany] = useState(companyStore.data);

  const activateCompanyModal = () => companyModalStore.updateState(true);

  companyStore.subject.subscribe(({data}: any) => {
    setCompany(data);
  });    

  const tipsSref = useSrefActive(TipsView.name,  {date: new Date().toISOString().split('T')[0] }, 'active');
  const flightsSref = useSrefActive(FlightsListView.name, {date: new Date().toISOString().split('T')[0] }, 'active');
  const onTips = useIsActive(TipsView.name, {});

  return (
    <div className="navbar w-row">
      <div className="w-col w-col-4">
        <button className={styles.floatLeft} type="button" onClick={ (e) => activateCompanyModal() } style={{ 'background': 'none' }}>
          { company?.name }
        </button>
        <ul className={`${styles.menu} ${styles.floatLeft}`}>
          <li>  
            { onTips ? <a {...flightsSref}>Flights</a>: <a {...tipsSref}>Tips Calculator</a> }
          </li>
        </ul>
      </div>
      <div className="w-col w-col-4">
        <div></div>
      </div>
      <div className="w-col w-col-2">
        {/* 
        <img
          src="https://uploads-ssl.webflow.com/62507da9a47e57af040ed27f/629262d0e5ab0b51d1f590f4_Search-PNG-File.png"
          loading="lazy" data-w-id="b6f5ddbb-2bde-b02c-8d23-f7f8d62b166b" height="50" alt="" /> 
        */}
      </div>
      <div className="w-col w-col-2">
        <div><span className="text-span">{userStore?.data?.name}</span></div>
      </div>
    </div>
  );
}

export const HeaderElement = (): JSX.Element => {
  useEffect(() => {
    companyModalStore.subscribe(false);
    companyModalStore.init();
  });

  return (
    <>
      <HeaderBar />
      <CompanyModal />
    </>
  );
}