import axios from 'axios';
import GuestDetailsView from './views/GuestDetails';
import TipsView from './views/TipsView';
import { FlightProps } from './components/Flight/Flight.types';
import { FlightsListView } from './views/FlightsList';
import {
  ReactStateDeclaration,
} from '@uirouter/react';
import { UserLoginForm } from './views/Login';
import { authStore, companyStore, TipRecipient, userStore } from './store/store.state';
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import GuestService from './components/Guest/Guest.service';
import UserService from './components/User/user.service';
import TipService from './components/Tip/tip.service';
import { TipDto } from './components/Tip/tip.interface';

const defaultResolvers = [
  {
    token: 'user',
    deps: ['$transition$'],
    resolveFn: (trans: any) => {
      const user = userStore.data;
      if(!user) {
        const cookies = new Cookies();
        if (cookies.get('checkin-token') && cookies.get('checkin-user')) {
          const u = cookies.get('checkin-user');
          authStore.updateState(cookies.get('checkin-token'));
          userStore.updateState(u);
          if (cookies.get('checkin-company')) {
            UserService.setActiveCompany(cookies.get('checkin-company'))
          } else {
            UserService.setActiveCompany(u.company[0]);
          }
          return user;
        } else {
          // trans.router.stateService.go(UserLoginForm.name);
        }
      } else {
        return user;
      }
    }
  },
];

export const LoginState: ReactStateDeclaration = {
  name: UserLoginForm.name,
  url: '/',
  component: UserLoginForm,
  resolve: [{
    token: 'loggedIn',
    deps: ['$transition$'],
    resolveFn: async (trans: any) => {
      const cookies = new Cookies();
      const user = userStore.data;
      if (!user && cookies.get('checkin-token') && cookies.get('checkin-user')) {
        const redir = cookies.get('redir-path');
        const u = cookies.get('checkin-user');
        authStore.updateState(cookies.get('checkin-token'));
        userStore.updateState(u);
        if (!companyStore.data) {
          if (cookies.get('checkin-company')) {
            UserService.setActiveCompany(cookies.get('checkin-company'))
          } else {
            UserService.setActiveCompany(u.company[0]);
          }
        }
      }
    }
  }]
};

export const FlightsListState: ReactStateDeclaration = {
  name: FlightsListView.name,
  url: '/flights/:date',
  component: FlightsListView,
  resolve: [
    ...defaultResolvers,
    {
      token: 'flights',
      deps: ['$transition$'],
      resolveFn: async (trans: any) : Promise<FlightProps[]> => {
        // TODO: introduce date format validation
        const date = trans.params()?.date;
        const flights = await GuestService.listBookings(date);
        return (flights as any).data.map((f:any) => {
          return {
            id: f._id,
            confirmation: `${f.company.nickname}${f.confirmation}`,
            firstName: f.first_name,
            lastName: f.last_name,
            email: f.email,
            pax: f.adults + (f.children || 0),
            phone: f.phone,
            balloon: f?.flight?.map((fl: any) => fl?.flight?.balloon?.name).join(', '),
            status: f?.meta?.checkin?.status,
            flagged: f?.meta?.checkin?.flagged,
          }
        });
      }
    }
  ]
};

export const GuestDetailsState: ReactStateDeclaration = {
  name: GuestDetailsView.name,
  url: '/flight/:id',
  component: GuestDetailsView,
  resolve: [
    ...defaultResolvers,
    {
      token: 'guest',
      deps: ['$transition$'],
      resolveFn: async (trans: any) => {
        const [f, messages] = (await GuestService.getBooking(trans.params().id)).data;
        return {
          id: f._id,
          day: f.day.split('T')[0],
          confirmation: `${f.company.nickname}${f.confirmation}`,
          firstName: f.first_name,
          lastName: f.last_name,
          email: f.email,
          pax: f.adults + (f.children || 0),
          phone: f.phone,
          balloon: f?.flight?.map((f: any) => f?.flight?.balloon?.name).join(', '),
          waivers: f.smartWaiver.map((w: any) => w.data),
          status: f?.meta?.checkin?.status,
          flagged: f?.meta?.checkin?.flagged,
          messages: messages.filter((m: any) => m.type === 'sms').map((m: any) => ({
            date: m.created,
            message: m.compiled,
          })),
          notes: f.notes,
        }
      }
    }
  ]
}

export const TipState: ReactStateDeclaration = {
  name: TipsView.name,
  url: '/tips/:date',
  component: TipsView,
  resolve: [
    ...defaultResolvers,
    {
      token: 'employees',
      deps: ['$transition$'],
      resolveFn: async (trans: any) => {
        const request = await UserService.tipEligible();
        return request.data;
      }
    },
    {
      token: 'squareTip',
      deps: ['$transition$', 'employees'],
      resolveFn: async (trans: any, employees: any) => {
        const date = trans.params()?.date;
        const hacked = await TipService.getHackedSquareTips(date, userStore?.data?._id!)
        return (hacked?.data || 0) + ((await TipService.getSquareTips(date, userStore?.data?._id!)).data.map((s: any) => s.tips).reduce((prev: any, tips: any) => prev + (tips.keyed + tips.tips), 0) / 100);
      }
    },
    {
      token: 'rezflowTip',
      deps: ['$transition$', 'employees'],
      resolveFn: async (trans: any, employees: any) => {
        const date = trans.params()?.date;
        return (await TipService.getRezflowTips(date, userStore?.data?._id!)).data;
      }
    },
    {
      token: 'tip',
      deps: ['$transition$', 'employees'],
      resolveFn: async (trans: any, employees: any) => {
        const date = trans.params()?.date;
        const squareTips = (await TipService.getSquareTips(date, userStore?.data?._id!)).data.map((s: any) => s.tips).reduce((prev: any, tips: any) => prev + (tips.keyed + tips.tips), 0) / 100;
        const request = await TipService.getTip(date, userStore?.data?._id!);
        if (request.data !== '') {
          const transpiled: TipDto = {
            _id: request.data._id,
            event: request.data.event,
            pilot: request.data.pilot._id,
            square: request.data.square,
            rezflow: request.data.rezflow,
            recipients: request.data.recipients.map((r: any) => {
              return {
                ...r,
                name: employees.find((e: any) => e._id === r._id)?.name,
              }
            })
          };
          return transpiled;

        } else {
          console.info('No tip found');
        }
      }
    },
    {
      token: 'event',
      deps: ['$transition$'],
      resolveFn: async (trans: any) => {
        const date = trans.params()?.date;
        const request = await TipService.getEvent(date);
        return request.data;
      }
    }

  ]
}


export const LogoutState: ReactStateDeclaration = {
  name: 'logout',
  url: '/logout',
  // component: GuestDetailsView,
  resolve: [
    {
      token: 'logout',
      deps: ['$transition$'],
      resolveFn: async (trans: any) => {
        UserService.logout(false)
      }
    }
  ]
}
const RouteStates: ReactStateDeclaration[] = [LoginState, FlightsListState, GuestDetailsState, TipState, LogoutState]

export default RouteStates;