import { useEffect, useState } from "react";
import { HeaderElement } from "../components/Header/Header";
import { TipRecipient, tipRecipientStore, userStore } from "../store/store.state";
import Select, { ActionMeta } from 'react-select';
import { Employee } from "../components/User/User.types";
import { Transition, useSrefActive } from "@uirouter/react";
import dayjs from "dayjs";
import TipService from "../components/Tip/tip.service";
import { TipDto } from "../components/Tip/tip.interface";
import { FlightsListView } from './FlightsList';

const TipsHeader = ({ transition }: { transition: Transition }): JSX.Element => {

  const dateParam = transition.params()?.date;
  const startDate = dayjs(dateParam).format('ddd, MMMM D, YYYY');

  const [date, setDate] = useState(startDate);

  const DateLink = ({ date }: any) => {
    const increaseSref = useSrefActive(TipsView.name, { date: dayjs(date).add(1, 'day').format('YYYY-MM-DD') }, "active");
    const decreaseSref = useSrefActive(TipsView.name, { date: dayjs(date).subtract(1, 'day').format('YYYY-MM-DD') }, "active");
    return (
      <span className="heading-link"><a {...decreaseSref}>&lt;</a> {date} <a {...increaseSref}>&gt;</a></span>
    );
  };

  return (
    <div className="w-container">
        <h1 className="w-basic-heading heading">
          Calulcate Tips:  <DateLink date={date} />
        </h1>
    </div>
  )
}

const TipsPilot = ({ employees, event, squareTip, rezflowTip, tip, transition }: { employees: Employee[], event: string, squareTip: number, rezflowTip: number, tip: TipDto, transition: Transition }): JSX.Element => {

  const dateParam = transition.params()?.date;

  function round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  const [user, setUser] = useState(userStore.data);
  const [canSubmit, setCanSubmit] = useState(false);
  const [rezflowTips, setRezflowTips] = useState<number | string | undefined>(rezflowTip ? rezflowTip : undefined);
  const [squareTips, setSquareTips] = useState<number | string | undefined>(squareTip ? squareTip : undefined);
  const [totalTips, setTotalTips] = useState<number>((Number.parseFloat(rezflowTips as string) || 0) + (Number.parseFloat(squareTips as string) || 0));
  const [recipients, setRecipients] = useState<TipRecipient[]>(tip ? tip.recipients : [{
    name: user?.name,
    user: user?._id,
    percent: 100,
    amount: 0,
    percent_override: false,
    amount_override: false,
  }]);

  const recipientChoices = employees.map((employee) => {
    return {
      value: employee._id,
      label: employee.name,
    }
  });

  const [calcState, setCalcState] = useState<'percent' | 'dollars'>('percent');

  const updateTips = (state:React.Dispatch<React.SetStateAction<number | string | undefined>>, event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target?.value.replace(/[^\d.-]/g, '');
    const rounded = round(Number.parseFloat(input));
    state(rounded);
  };

  const updateTotals = () => {
    setTotalTips((Number.parseFloat(rezflowTips as string) || 0) + (Number.parseFloat(squareTips as string) || 0));
  }

  const sanitizeNumber = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const allowed = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Enter', 'ArrowUp', 'ArrowDown'];
    if (target.value) { // check if the input field has a value
      const noSelection = (target.selectionEnd || 0) - (target?.selectionStart || 0) === 0;
      if (target.value.includes('.') && noSelection) { // check if the value contains a decimal point
        if (event.key === '.' || (target.value.split('.')[1].length === 2 && Number.isInteger(Number(event.key)))) {
          // check if the key pressed is a decimal point, or if the value already has two decimal places and the key pressed is an integer
          event.preventDefault(); // if either of the above conditions is true, prevent the default behavior of the event (i.e. prevent the key from being entered in the input field)
        }
      }
    }
    // regext for only numbers, the word tab or backspace
    if(!(/[0-9.-]+$/).exec(event.key)) {
      if (!allowed.includes(event.key)) {
        event.preventDefault();
      }
    }
  }

  const addRecipient = () => {
    recipients.push({ percent_override: false } as TipRecipient);
    setRecipients([...recipients]);
  }

  const submitTips = async () => {
    if (tip?._id) {
      await TipService.updateTip(tip._id, {
        event,
        pilot: user!._id,
        rezflow: rezflowTips ? Number.parseFloat(rezflowTips as string) : 0,
        square: squareTips ? Number.parseFloat(squareTips as string) : 0,
        recipients: recipients.map((recipient) => {
          return {
            user: recipient.user!,
            percent: recipient.percent!,
            amount: Number.parseFloat(recipient.amount! as string),
            percent_override: recipient.percent_override!,
            amount_override: recipient.amount_override!,
          }
        }),
      });
      transition.router.stateService.go(FlightsListView.name, { date: new Date().toISOString().split('T')[0] })
    } else {
      await TipService.saveTip({
        event,
        pilot: user!._id,
        rezflow: rezflowTips ? Number.parseFloat(rezflowTips as string) : 0,
        square: squareTips ? Number.parseFloat(squareTips as string) : 0,
        recipients: recipients.map((recipient) => {
          return {
            user: recipient.user!,
            percent: recipient.percent!,
            amount: Number.parseFloat(recipient.amount! as string),
            percent_override: recipient.percent_override!,
            amount_override: recipient.amount_override!,
          }
        }),
      });
      transition.router.stateService.go(FlightsListView.name, { date: new Date().toISOString().split('T')[0] })
    }
  }

  const totalDollars = (recipients: TipRecipient[]) => {
    return recipients.map((r: any) => r?.amount ).reduce((p:number, r: any) => p+Number.parseFloat(r), 0)
  }

  const totalPercentage = (recipients: TipRecipient[]) => {
    return recipients.map((r: any) => r?.percent ).reduce((p:number, r: any) => p+Number.parseFloat(r), 0)
  }

  const adjustTipsPerc = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newRecipients = [...recipients];
    const percent = Number.parseInt(event.target?.value);
    newRecipients[index].percent = round(percent);
    newRecipients[index].amount = (totalTips || 0) * (percent / 100);
    newRecipients[index].percent_override = true;
    newRecipients[index].amount_override = false;
    setRecipients(newRecipients);
    rebalanceRecipients();
  };

  const adjustTipsDollar = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const newRecipients = [...recipients];
    const dollars = Number.parseFloat(event.target?.value) || 0
    newRecipients[index].amount = event.target.value;
    newRecipients[index].percent = round((dollars / (totalTips || 0)) * 100);
    newRecipients[index].percent_override = false;
    newRecipients[index].amount_override = true;
    setRecipients(newRecipients);
    // event.target.value = dollars.toString();
    rebalanceRecipients();
  };

  const removeRecipient = (index: number) => {
    recipients.splice(index, 1);
    setRecipients([...recipients]);
  }

  const rebalanceRecipients = () => {

    const lockedPercentage = 100 - (recipients.reduce((acc, cur) => {
      if (cur.percent_override) {
        return acc + (cur.percent || 0);
      }
      if (cur.amount_override) {
        return acc + ((Number.parseFloat(cur.amount as string) || 0) / (totalTips || 0) * 100);
      }
      return acc;
    }, 0) || 0);

    const calculatedPercent = round(lockedPercentage / (recipients.filter((rec) => (!rec.percent_override && !rec.amount_override)).length));

    setRecipients(() => recipients.map((recipient, index) => {
      (document.querySelector(`input[name="recipient[${index}]dollar"]`) as any).value = recipient.amount;
      let derivedPercentage = (
        // if the amount isn't overridden, calculate the percentage of the total tips
        !recipient.amount_override ? 
          calculatedPercent :
          round(((Number.parseFloat(recipient.amount as string) || 0) /(totalTips || 0)) * 100) || 0
      );
      
      // if (index === 0 && recipient.percent) {
      //   console.log('pilot specific shit');
      //   const balanceRemaining = round(100 - totalPercentage(recipients));
      //   // gonna freak out, huh?
      //   derivedPercentage = derivedPercentage + balanceRemaining
      // }

      return { 
        ...recipient,
        percent: recipient.percent_override ? recipient.percent : derivedPercentage,
        amount: recipient.amount_override ? recipient.amount : (
          !recipient.percent_override ? 
          // calculate the percentage of the total tips
          round((totalTips || 0) * (calculatedPercent / 100)) : 
          recipient.amount
        )
      };
    }));
  }

  useEffect(() => {
    rebalanceRecipients();
    const unassigned: TipRecipient[] = recipients.filter((r: TipRecipient) => !r?.user);
    setCanSubmit(unassigned.length === 0 && (totalTips || 0) > 0);
  }, [JSON.stringify(recipients), totalTips, canSubmit]);

  const changeRecipient = (newValue: any, meta: ActionMeta<any>, index: number) => {
    const newRecipients = [...recipients];
    newRecipients[index].name = newValue.label;
    newRecipients[index].user = newValue.value;
    setRecipients(newRecipients);
  }

  const [overrideSquare, setSquareOverride] = useState(false);

  const handleSquareOverrideChange = () => {
    setSquareOverride(!overrideSquare);
  };

  return (
    <>
    <div className="w-container">
      <h3 className="heading-4">Pilot: { user?.name }</h3>
      <div className="columns w-row">
        <div className="w-col w-col-4">
          <h4 className="heading-5">Total RezFlow Tips:</h4>
        </div>
        <div className="w-col w-col-8">
          <div className="form-field">
            <input type="text" name="rezflowTips" className="text-field w-input" 
              defaultValue={rezflowTips} 
              disabled={true}
              onChange={(e) => updateTips(setRezflowTips, e) } 
              onKeyUp={updateTotals} 
              onKeyDown={(e) => sanitizeNumber(e) }/>
          </div>
        </div>
      </div>
      <div className="columns w-row">
        <div className="w-col w-col-4">
          <h4 className="heading-5">
            Total Square Tips:&nbsp;
            <label htmlFor="squareOverride" style={{float: 'right'}}>
              <small>Override&nbsp;</small>
              <input type="checkbox" name="squareOverride" id="squareOverride" 
                checked={overrideSquare}
                onChange={handleSquareOverrideChange} />
            </label>
          </h4>
        </div>
        <div className="w-col w-col-8">
          <div className="form-field">
            <input type="text" name="squareTips" className="text-field w-input" 
              defaultValue={squareTips} 
              disabled={!overrideSquare}
              onChange={(e) => updateTips(setSquareTips, e) } 
              onKeyUp={updateTotals} 
              onKeyDown={(e) => sanitizeNumber(e) }/>
          </div>
        </div>
      </div>
      <div className="columns w-row">
        <div className="w-col w-col-4">
          <h4 className="heading-5">Total Tips:</h4>
        </div>
        <div className="w-col w-col-8">
          <div className="form-field">
            <input type="text" disabled name="totalTips" className="text-field w-input"value={totalTips}/>
          </div>
        </div>
      </div>
      <div className="columns w-row">
        <div className="w-tab-menu" role="tablist">
          {
            ['PERCENT', 'DOLLAR AMOUNT'].map((tab, index) => {
              const type = {'PERCENT': 'percent', 'DOLLAR AMOUNT': 'dollars'}[tab];
              return (
                <button key={index} className={`w-inline-block w-tab-link ${ calcState === type ?  'w--current' : ''}`} role="tab" onClick={ () => setCalcState(type as any) }>
                  <div>{tab}</div>
                </button>
              );
            })
          }
        </div>
      </div>
      {/* Receiver of tips */}
      {
        recipients.map((recipient: any, index: number) => {
          return (
            <div key={index} className="tipRecipient">
              <div className={`columns w-row`}>
                <div className="w-col w-col-4">
                  <h4 className="heading-5">
                    {
                      index === 0 ? (
                        <span style={{margin: '7px', display: 'inline-block'}}>
                          { user?.name} (you)
                        </span>
                        ) : (
                        <Select
                          defaultValue = {
                            recipientChoices.filter(option => 
                                option.value === recipient.user)
                          }
                          required
                          onChange = { (newValue, actionMeta: ActionMeta<any>) => changeRecipient(newValue, actionMeta, index) }
                          // onBlur={() => props.input.onBlur(props.input.value)}
                          options={recipientChoices.filter((choice) => {
                            return !recipients.some((r) => r.user === choice.value);
                          })}
                          placeholder={`Choose Recipient`}
                        />  
                      )
                    }
                  </h4>
                </div>
                {/* Percentage Field */}
                <div className="w-col w-col-2" style={{ display: calcState !== 'percent' ? 'none' : 'inherit'}}>
                  <div className="form-field" style={{ paddingRight: '30px'}}>
                    <input type="number" name={`recipient[${index}]percent`}
                      style={{ textAlign: 'right'}}
                      className={`text-field w-input ${recipient.percent_override ? 'overridden' : ''}`}
                      value={ recipient?.percent }
                      max="100"
                      onChange={ (e) => adjustTipsPerc(index, e)} />
                    <span className="suffix" style={{ position: 'absolute', right: '20px', top: '20px'}}>
                      %
                    </span>
                  </div>
                </div>
                {/* Dollar Amount Field */}
                <div className="w-col w-col-2" style={{ display: calcState !== 'dollars' ? 'none' : 'inherit'}}>
                  <div className="form-field" style={{ paddingLeft: '30px'}}>
                    <span className="prefix" style={{ position: 'absolute', left: '20px', top: '20px'}}>$</span>
                    <input type="text" name={`recipient[${index}]dollar`}
                      style={{ textAlign: 'left'}}
                      className={`text-field w-input ${recipient.amount_override ? 'overridden' : ''}`}
                      defaultValue={ recipient?.amount }
                      onChange={ (e) => adjustTipsDollar(index, e)}
                      onKeyDown={(e) => sanitizeNumber(e) }/>
                  </div>
                </div>
                {
                  index !== 0 && (
                    <div className="w-col w-col-1">
                      <button type="button" className="removeRecipient" onClick={ (e) => removeRecipient(index)}>x</button>
                    </div>
                  )
                }
              </div>
            </div>
          );
        })
      }

      <div className="columns w-row">
        <button className="button add-staff w-button" onClick={ () => addRecipient() }>+ ADD TEAM MEMBER</button>
      </div>

      <div className="columns w-row">
        <button style={{ marginTop: '20px' }} className="button w-button" onClick={ () => submitTips() } disabled={canSubmit === false}>SAVE TIPS</button>
      </div>

    </div>
    </>
  );
}

const TipsView = ({ employees, event, transition, tip, squareTip, rezflowTip }: { employees: Employee[], event: { _id: string }, transition: Transition, tip: TipDto, squareTip: number, rezflowTip: number }): JSX.Element => {
  return (
    <>
      <HeaderElement />
      <TipsHeader transition={transition}/>
      <TipsPilot employees={employees} event={event._id} squareTip={squareTip} rezflowTip={rezflowTip} tip={tip} transition={transition} />
    </>
  )
}

export default TipsView;