import { CheckinStatus, FlightProps } from "../Flight/Flight.types";
import styles from './Guest.module.css';
import { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import GuestService from "./Guest.service";

export const GuestDetails = ({ guest }: { guest: FlightProps }): JSX.Element => {
  return (
    <>
      <h1 className={ `w-basic-heading heading-2 ${ styles.header }`}>{ guest.lastName}, { guest.firstName }</h1>
      <div className={styles.labels}>Guests:</div>
      <div className={styles.values}>{ guest.pax }</div>
      <div className={styles.labels}>Confirmation:</div>
      <div className={styles.values}>{ guest.confirmation }</div>
      <div className={styles.labels}>Phone:</div>
      <div className={styles.values}>{ guest.phone }</div>
      <div className={styles.labels}>Email:</div>
      <div className={styles.values}>{ guest.email }</div>
      <div className={styles.labels}>Balloon:</div>
      <div className={styles.values}>{ guest.balloon }</div>
    </>
  );
}

export const GuestCheckInStatus = ({ guest }: { guest: FlightProps }): JSX.Element => {
  const [status, setStatus] = useState(guest.status || undefined);
  useEffect(() => {
    if ((guest.status && guest.status !== status) || (!guest.status && status)) {
      GuestService.updateStatus(guest.id, status)
    }
  });

  function getStatus(status: CheckinStatus): void {
    setStatus(status);
  }

  return (
    <>
      <h3 className={`section-header ${styles['section-header']}`}>Check-In Status</h3>
      <div className={`select-box w-form ${styles['grid-1']}`}>
        <form id="checkin-status" name="checkin-status" method="get" className="select-box">
          <select defaultValue={guest.status} id="field" name="field" className="select-box w-select" onChange={ (e) => getStatus(e.target.value as any) }>
            <option value="">Guest Not Here</option>
            <option value="contacted">Guest Was Called</option>
            <option value="checked-in">Guest is Here</option>
            <option value="flown">Flown</option>
            <option value="no-show">No Show</option>
          </select>
        </form>
        <div className="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div className="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>

      <div className={`${styles['grid-1']}`}>
        <div className="call-button">
          <a href={'tel:' + guest.phone}>Call</a>
        </div>
        <div className="call-button">
          <a href={'sms:' + guest.phone}>Text</a>
        </div>
        <div className="charge-complete">Charge Successful</div>
        <div className="charge-button">
          <div>Charge</div>
        </div>
      </div>
    </>
  );
}

export const GuestSignedWaivers = ({ guest }: { guest: FlightProps }): JSX.Element => {
  const [showResendList, setShowWaiverTabs] = useState(false)
  const resendWaiver = () => setShowWaiverTabs(true)
  function getAge(dateString: string) {
    const today = dayjs();
    const birthDate = dayjs(dateString);
    return today.diff(birthDate, 'year');
  }

  const [flagged, toggleFlag] = useState(guest.flagged);

  useEffect(() => {
    if (!guest.flagged && flagged) {
      guest.flagged = true;
      GuestService.toggleFlag(guest.id, guest.flagged)
    } else if (guest.flagged && !flagged) {
      guest.flagged = false;
      GuestService.toggleFlag(guest.id, guest.flagged)
    }
  });

  return (
    <>
      <h3 className="w-basic-heading section-header">Signed Waivers</h3>
      <div className="info-block">
        <div className="w-row">
          {
            guest.waivers?.map((waiver, index) => {
              let weight = waiver?.participants?.map((participant, index) => {
                return participant?.customParticipantFields ? Object.values(participant.customParticipantFields)
                  .find(field => field.displayText === 'Guest Weight')?.value + ' lbs' || '' : 'n/a'
              }).join(', ');

              return (
                <div key={index} className="w-col w-col-4">
                  <div>{ waiver.firstName } { waiver.lastName }, { getAge(waiver.dob) } years old, { weight }</div>
                </div>
              );
            })
          }
        </div>
      </div>
      <button className={`flag-waiver-button ${ (!flagged) ? 'not-flagged' : 'is-flagged'}`} onClick={ () => toggleFlag(!flagged) }>
        <div style={{display: `${ flagged ? 'block' : 'none' }` }}>Remove Waiver Flag</div>
        <div style={{display: `${ !flagged ? 'block' : 'none'}` }} className="unflagged-waiver-button">Flag Waiver</div>
      </button>
      <button type="button" className="resend-waiver-button w-inline-block" onClick={ resendWaiver }>
        <div>Waiver QR</div>
      </button>

      <GuestWaiverTabs guest={ guest } style={{ display: showResendList ? 'block' : 'none' }} />
    </>
  );
}

export const GuestWaiverTabs = ({ guest, style }: { guest: FlightProps, style: any }): JSX.Element => {

  const [activeTab, setShowWaiverTabs] = useState(1);
  const [waiverImage, setWaiver] = useState('');
  const activateTab = (n: number) => setShowWaiverTabs(n)

  GuestService.getWaiverQr(guest.id).then(({data}) => {
    setWaiver(data)
  });

  return (
    <div style={style} className="waiver-resend-container w-tabs">
      <div className="waiver-tabs w-tab-menu">
        {/* <button onClick={() => activateTab(0) } className={`tab-names w-inline-block w-tab-link ${ activeTab === 0 ? 'w--current' : ''}`}>
          <div>TEXT MESSAGE</div>
        </button> */}
        <button onClick={() => activateTab(1) }className={`tab-names w-inline-block w-tab-link ${ activeTab === 1 ? 'w--current' : ''}`}>
          <div>QR CODE</div>
        </button>
        {/* <button onClick={() => activateTab(2) } className={`tab-names w-inline-block w-tab-link ${ activeTab === 2 ? 'w--current' : ''}`}>
          <div>EMAIL</div>
        </button> */}
      </div>
      <div className="w-tab-content">
        {/* <div className={`w-tab-pane ${ activeTab === 0 ? 'w--tab-active' : ''}`}>
          <div className="waiver-resend-row w-row">
            <div className="w-col w-col-3">
              <div>{ guest.phone }</div>
            </div>
            <div className="w-col w-col-9">
              <a href="#" className="send-message-button w-button">SEND MESSAGE</a>
            </div>
          </div>
          <div className="waiver-resend-row w-row">
            <div className="w-col w-col-3">
              <div></div>
            </div>
            <div className="w-col w-col-9">
              <a href="#" className="send-message-button w-button">SEND MESSAGE</a>
            </div>
          </div>
          <div><span className="add-info-text-link">+ ADD PHONE NUMBER</span></div>
        </div> */}
        <div className={`w-tab-pane ${ activeTab === 1 ? 'w--tab-active' : ''}`}>
          <img loading="lazy" src={waiverImage} alt="" className="qr-code" />
        </div>
        {/* <div className={`w-tab-pane ${ activeTab === 2 ? 'w--tab-active' : ''}`}>
          <div className="waiver-resend-row w-row">
            <div className="w-col w-col-3">
              <div>{ guest.email }</div>
            </div>
            <div className="w-col w-col-9">
              <a href="#" className="send-message-button w-button">SEND EMAIL</a>
            </div>
          </div>
          <div><span className="add-info-text-link">+ ADD EMAIL ADDRESS</span></div>
        </div> */}
      </div>
    </div>
  )
}

export const GuestMessages = ({ guest }: { guest: FlightProps }): JSX.Element => {

  function DynamicHTML(html: string) {
    return <div dangerouslySetInnerHTML={ { __html: html}} />;
  }

  return (
    <>
      <h3 className="section-header">Message History</h3>
      {
        guest?.messages?.map((message, index) => {
          return <>
            <div className="list-row w-row" key={index}>
              <div className="w-col w-col-1">
                <div>{dayjs(message.date).format('MM/DD/YY HH:mma')}</div>
                <img src="https://uploads-ssl.webflow.com/62507da9a47e57af040ed27f/6251934e71cdba8a7c502da7_Screen%20Shot%202022-04-09%20at%207.07.25%20AM.png" loading="lazy" alt="" className="message-icon" />
              </div>
              <div className="w-col w-col-9">
                {DynamicHTML(message.message)}
              </div>
              <div className="w-col w-col-2">
                <img src="https://uploads-ssl.webflow.com/62507da9a47e57af040ed27f/6251934e88caf244b59136cf_Screen%20Shot%202022-04-09%20at%207.07.39%20AM.png" loading="lazy" alt="" className="message-icon" />
              </div>
            </div>
          </>;
        })
      }
    </>
  );
}

export const GuestNotes = ({ guest }: { guest: FlightProps }): JSX.Element => {

  return (
    <>
      <h3 className="section-header">Reservation Notes</h3>
      <div>
        <p>{ guest.notes?.instructions }</p>
        <p>{ guest.notes?.memo }</p>
        <p>{ guest.notes?.occasion }</p>
        <p>{ guest.notes?.internal }</p>
      </div>
    </>
  );
}
