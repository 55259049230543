import Flight from '../components/Flight/Flight';
import { FlightProps } from '../components/Flight/Flight.types';
import * as C from '../components/Flight/Flight.consts';
import { useLayoutEffect, useState } from 'react';
import { Transition, useSrefActive } from '@uirouter/react';
import { UserLoginForm } from './Login';
import { userStore } from '../store/store.state';
import dayjs from 'dayjs';
import { HeaderElement } from '../components/Header/Header'

export const FlightsHeader = (): JSX.Element => {  
  return (
    <div className="column-names w-row">
      <div className={C.FLIGHT_CONF_COL_CLASS}>
        <div>#</div>
      </div>
      <div className={C.FLIGHT_NAME_COL_CLASS}>
        <div>Name</div>
      </div>
      <div className={C.FLIGHT_PAX_COL_CLASS}>
        <div>PAX</div>
      </div>
      <div className={C.FLIGHT_PHONE_COL_CLASS}>
        <div>Phone</div>
      </div>
      <div className={C.FLIGHT_BALLOON_COL_CLASS}>
        <div>Balloon</div>
      </div>
    </div>
  )
};

export const Flights = ({ flights, transition }: { flights: FlightProps[], transition: Transition }): JSX.Element => {
  return (
    <>
    {
      flights.map((flight, index) => {
        return <Flight {...flight} key={index.toString()} />;
      })
    }
    </>
  )
};

export const FlightsList = ({ flights, transition }: { flights: FlightProps[], transition: Transition }): JSX.Element => {
  const [user, setUser] = useState(userStore.data);
  
  useLayoutEffect(() => {
    if (!user) {
      // transition.router.stateService.go(UserLoginForm.name);
    }
  });

  return (
    <>
      <FlightsHeader />
      <div className="w-dyn-list">
        <div role="list" className="w-dyn-items">
          <Flights flights={flights} transition={transition} />
        </div>
      </div>
    </>
  );
};

export const FlightsListView = ({ flights, transition }: { flights: FlightProps[], transition: Transition }): JSX.Element => {
  const dateParam = transition.params()?.date;
  const startDate = dayjs(dateParam).format('ddd, MMMM D, YYYY');


  const DateLink = ({ date }: any) => {
    const increaseSref = useSrefActive(FlightsListView.name, { date: dayjs(date).add(1, 'day').format('YYYY-MM-DD') }, "active");
    const decreaseSref = useSrefActive(FlightsListView.name, { date: dayjs(date).subtract(1, 'day').format('YYYY-MM-DD') }, "active");
    return (
      <span className="heading-link"><a {...decreaseSref}>&lt;</a> {date} <a {...increaseSref}>&gt;</a></span>
    );
  };

  const [date, setDate] = useState(startDate);

  return (
    <>
      <HeaderElement />
      <div className="w-container">
        <h1 className="w-basic-heading heading">
          BOOKINGS:  <DateLink date={date} />
        </h1>
        <FlightsList flights={flights.filter(f => f.status !== 'no-show')} transition={transition}></FlightsList>
      </div>
      <div className="w-container">
        <h2 className="section-header">
          No Show
        </h2>
        <FlightsList flights={flights.filter(f => f.status === 'no-show')} transition={transition}></FlightsList>
      </div>
    </>
  )
}

export default FlightsList;
