import { Subject } from 'rxjs';

export class Store<T> {

  readonly initialState: { data: T | undefined, updateCount: number } = { data: undefined, updateCount: 0 };
  state: { data: T | undefined, updateCount: number } = { data: undefined, updateCount: 0 };
  subject: Subject<{ data: any, updateCount: number }>;

  constructor() {
    this.subject = new Subject();
  }
  
  init() {
    this.subject.next(this.initialState);
  }

  subscribe(setState: any) {
    this.subject.subscribe(setState)
  }

  updateState(data: any) {
    this.state = {
      ...this.state,
      data,
      updateCount: this.state.updateCount += 1,
    };
    this.subject.next(this.state);
  }

  clearState() {
    this.state = {...this.state, data: undefined};
    this.subject.next(this.state);
  }

  unsubscribe() {
    this.subject.unsubscribe();
  }

  get data() {
    return this.state?.data;
  }

} 