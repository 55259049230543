import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  pushStateLocationPlugin,
  UIRouter,
  UIRouterReact,
} from '@uirouter/react';
import States from './App.routes';
import { UserLoginForm } from './views/Login';
import { routerStore } from './store/store.state';
// import './index.css';

const routerConfig = (router: UIRouterReact) => {
  routerStore.init();
  routerStore.updateState(router);
  router.urlService.rules.initial({ state: UserLoginForm.name })
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <UIRouter 
      plugins={[pushStateLocationPlugin]} 
      states={States}
      config={routerConfig}
    >
      <App/>
    </UIRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
