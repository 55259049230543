import axios, { AxiosError } from "axios";
import { authStore, companyStore, userStore } from "../../store/store.state";
import { CheckinStatus } from "../Flight/Flight.types";
import UserService from "../User/user.service";

export default class GuestService {
  static async updateStatus(id: string, status: CheckinStatus) {
    try {
      return await axios.patch(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/booking/${id}/status`,
        { status },
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async toggleFlag(id: string, flagged: boolean) {
    try {
      return await axios.patch(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/booking/${id}/flag`,
        { flagged },
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async getBooking(id: string) {
    try {
      return await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/booking/${id}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async getWaiverQr(id: string) {
    try {
      return await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/waiver/qr/${id}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }

  static async listBookings(date: string) {
    try {
      return await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/bookings/${date}`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }
}
