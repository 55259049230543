import { UIRouterReact } from "@uirouter/react";
import { Store } from "../helpers/store.helper";

export const userStore = new Store<{
  name: string;
  _id: string;
  email: string;
  company: Array<{ name: string; nickname: string; id: string }>;
}>();

export const authStore = new Store<string>();

export const companyModalStore = new Store<boolean>();
export const companyStore = new Store<{
  _id: string;
  name: string;
  nickname: string;
}>();

export const routerStore = new Store<UIRouterReact>();

export interface TipRecipient {
  user?: string;
  name?: string;
  percent?: number;
  amount?: number | string;
  percent_override?: boolean;
  amount_override?: boolean;
}

export const tipRecipientStore = new Store<TipRecipient[]>();
