/* eslint-disable no-restricted-globals */
import axios, { AxiosError } from "axios";
import Cookies from "universal-cookie";
import { authStore, companyStore, userStore } from "../../store/store.state";

export default class UserService {
  static async login(email: string, password: string) {
    return axios.post(`${process.env.REACT_APP_CHECKIN_API}/auth/login`, {
      email,
      password,
    });
  }

  static logout(redirect = true) {
    const cookies = new Cookies();
    if (redirect) {
      cookies.set("redir-path", location.pathname, { path: "/" });
    } else {
      cookies.remove("redir-path");
    }
    cookies.remove("checkin-user");
    cookies.remove("checkin-token");
    cookies.remove("checkin-company");
    authStore.clearState();
    userStore.clearState();
    setTimeout(() => {
      location.href = "/";
    }, 100);
  }

  static setActiveCompany(company: {
    _id: string;
    name: string;
    nickname: string;
  }) {
    companyStore.updateState(company);
    const cookies = new Cookies();
    cookies.set("checkin-company", JSON.stringify(company), { path: "/" });
  }

  static async tipEligible() {
    try {
      return await axios.get(
        `${process.env.REACT_APP_CHECKIN_API}/check-in/employees/tip-eligible`,
        {
          headers: {
            Authorization: `${authStore.data}`,
            Company: `${companyStore?.data?._id}`,
          },
        }
      );
    } catch (error: any) {
      if (error.constructor === AxiosError) {
        if (error.response?.status === 401) {
          UserService.logout();
        }
      }
      throw error;
    }
  }
}
