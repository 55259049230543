import React from 'react';
import styles from './Flight.module.css';
import { FlightProps } from './Flight.types';
import * as C from './Flight.consts';
import { useSrefActive } from "@uirouter/react";
import GuestDetailsView from '../../views/GuestDetails';


const Flight = (flight: FlightProps): JSX.Element => {
  const guestSref = useSrefActive(GuestDetailsView.name, flight, 'active');
  return (
    <>
      <div role="listitem" className="w-dyn-item">
        <a {...guestSref} className="list-linking-block w-inline-block">
          <div className={ `list-row w-row ${ flight.status ? styles[flight.status] : ''} ${ flight.flagged ? styles.flagged : ''}` }>
            <div className={C.FLIGHT_CONF_COL_CLASS}>
              <div>{flight.confirmation} {flight.flagged}</div>
            </div>
            <div className={C.FLIGHT_NAME_COL_CLASS}>
              <div>{flight.lastName}, {flight.firstName}</div>
            </div>
            <div className={C.FLIGHT_PAX_COL_CLASS}>
              <div>{flight.pax}</div>
            </div>
            <div className={C.FLIGHT_PHONE_COL_CLASS}>
              <div>{flight.phone}</div>
            </div>
            <div className={C.FLIGHT_BALLOON_COL_CLASS}>
              <div>{flight.balloon}</div>
            </div>
          </div>
        </a>
      </div>
    </>
  );
};

export default Flight;
